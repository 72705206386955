.logoImage {
    height: 70px;
    width: 70px;
}

.navbarText ul li a {
    font-size: 21px;
    font-family: "Montserrat";
    letter-spacing: 0.5px;
    color: black;
}

.navbarText ul li a:hover {
    color: rgb(101, 101, 101);
}

.main-top {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.main-top-img {
    height: 80px;
    width: 276px;
}


.main-left {
    padding-top: 100px;
}

.btnMain {
    height: 50px;
    width: 80%;
    align-items: center;
    background-color: #79bb33;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 23px;
    font-weight: 600;
    height: 55px;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
}

.main-right {
    padding-top: 1px;
    position: relative;
    max-width: 100%;
    height: 500px;
}

.main-right::before {
    content: "";
    background-image: url(./Images/plate.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.7;
}

.main-right-1 {
    height: 340px;
    width: 438px;
    text-align: left;
    background-color: white;
    border: 0px solid black;
    border-radius: 5px;
    position: relative;
    left: 100px;
    top: 90px;
    opacity: 0.8;
}

.main-right-text {
    padding-top: 40px;
    margin-left: 30px;
    margin-right: 50px;
}

.mr-p-1 {
    margin-bottom: 0px;
    font-size: 38px;
    font-weight: 700;
    color: #2f9111;
    opacity: 1;
    padding-top: 25px;
}

.mr-p-2 {
    margin-top: 0px;
    font-size: 38px;
    font-weight: 700;
    color: #2f9111;
    opacity: 1;
    padding-top: 10px;
}


.mr-p-3 {
    font-size: 22px;
    font-weight: 500;
    color: #2f9111;
}

.footer {
    background-color: black;

}

.icon {
    font-size: 30px;
    color: white;
}

.icon:hover {
    color: #79bb33;
}

.btnModal {
    background-color: #3db817;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    padding: 2px;
    padding-left: 9px;
    padding-right: 9px;
}

@media (max-width: 480px) {
    .logoImage {
        height: 50px;
        width: 50px;
    }

    .main-top {
        padding-top: 1.5rem;
        padding-bottom: 0rem;
    }

    .main-top-img {
        padding-bottom: 0px;
        height: 80px;
        width: 60vw;

    }

    .main-left {
        padding-top: 20px;
    }

    .btnMain {
        width: 100%;
        font-size: 19px;
        text-transform: capitalize;
    }

    .main-right {
        margin-top: 40px;
        padding-top: 0px;
        position: relative;
        max-width: 100%;
        height: 424px;
    }

    .main-right::before {
        content: "";
        background-image: url(./Images/plate.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.7;
    }

    .main-right-1 {
        height: 240px;
        width: 260px;
        text-align: left;
        background-color: white;
        border: 0px solid black;
        border-radius: 5px;
        position: relative;
        left: 50px;
        top: 96px;
        opacity: 0.8;
    }

    .main-right-text {
        padding-top: 7px;
        margin-left: 30px;

    }


    .mr-p-1 {
        font-size: 29px;
        font-weight: 700;
        color: #2f9111;
        opacity: 1;
    }

    .mr-p-2 {
        font-size: 29px;
        font-weight: 700;
        color: #2f9111;
        opacity: 1;
    }


    .mr-p-3 {
        font-size: 13px;
        font-weight: 500;
        color: #2f9111;
    }

    .icon {
        font-size: 25px;
        color: white;
    }

}